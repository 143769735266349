<template>
  <div class="odd">
    <button
      v-bind:disabled="locked"
      :class="['btn odd-btn', statusClass, picked]"
      @click="
        addPick(
          sport_id,
          match_id,
          market_name,
          market_id,
          home_team,
          away_team,
          producer_id,
          specifier,
          pick,
          oddStatus,
          live
        )
      "
      v-bind:id="
        getOddID(
          match_id,
          market_id,
          outcome_id,
          specifier,
          boosted ? 'boosted' : 'odd'
        )
      "
      v-bind:oddStatus="oddStatus"
      v-bind:oddValue="odd"
      v-bind:oddActive="oddActive"
      v-bind:producerId="producer_id"
      v-bind:producerStatus="producerStatus"
    >
      <!-- <p style="color: red" v-if="locked">sdd</p> -->
      <div class="grey-text text-center odd-indicator">{{ outcome_name }}</div>
      <button>
        <span v-if="locked" style="height: 1.5em">
          <LockIcon :size="iconSize" fill-color="var(--text-color)"></LockIcon>
        </span>
        <span v-else>
          <p style="margin: 0; padding: 0" class="odd-type">
            <span
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span
                style="display: block; text-wrap: wrap; word-break: break-all"
              >
                {{ alias }}
              </span>
              <span
                class="odd-direction"
                style="margin-left: 5px"
                v-bind:class="directionClass"
              ></span>
            </span>
          </p>
          <span class="odd-number" style="width: 20px"
            ><span v-if="boostedGame">
              <svg
                style="margin-bottom: -4px"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
              >
                <path
                  d="M0.832031 6.5L4.72677 0.5L8.83203 6.69048L6.09519 5.45238L5.88466 10.7857L3.33203 9.5L2.83203 14.5L2.09519 8.02381L3.25308 8.30952V5.54762L0.832031 6.5Z"
                  fill="#CC1717"
                />
              </svg>
            </span>
            {{ odd }}</span
          >
        </span>
      </button>
    </button>
  </div>
</template>

<style scoped>
span {
  margin: 0;
  cursor: pointer;
}
.odd-box {
  width: 100%;
}
.odd {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}

.odd > button {
  min-height: 50px;
}
.odd-box div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  width: 100%;
  span {
    font-weight: 500;
  }
}

/* .oddActive {
  border: 2px solid red;
} */
.odd-number {
  /* outline: 1px solid red; */
}
.odd-type {
  /* outline: 1px solid red; */
  color: var(--sub-text-color);
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  /* margin: 0 auto; */
  text-align: center;
  margin-bottom: -1px;
}
</style>

<script>
const LockIcon = () => import("vue-material-design-icons/Lock.vue");

export default {
  name: "Odd",
  props: {
    pick: {
      required: true,
    },
    away_team: {
      required: true,
    },
    home_team: {
      required: true,
    },
    market_name: {
      required: true,
    },
    sport_id: {
      required: true,
    },
    show_direction: {
      required: false,
      default: true,
    },
    previous_odds: {
      required: true,
    },
    odds: {
      required: true,
    },
    probability: {
      required: false,
      default: 0,
    },
    match_id: {
      required: true,
    },
    market_id: {
      required: true,
    },
    outcome_id: {
      required: true,
    },
    outcome_name: {
      required: true,
    },
    specifier: {
      required: true,
    },
    active: {
      required: true,
    },
    status: {
      required: true,
      default: null,
    },
    producer_id: {
      required: true,
    },
    producer_status: {
      required: true,
    },
    alias: {
      required: true,
      default: "",
    },
    boosted: {
      required: false,
      default: false,
    },
    boostedGame: {
      required: false,
      default: false,
    },
    live: {
      required: false,
      default: false,
    },
    index: {
      required: true,
      default: null,
    },
    locked: {
      required: false,
      default: false,
    },
  },
  methods: {
    oddsChange: function (payload) {
      var vm = this;
      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;
      if (parseFloat(payload.odd) !== parseFloat(vm.odd)) {
        vm.previousOdds = vm.odd;
        vm.odd = payload.odd;
      }

      vm.locked =
        parseInt(vm.oddStatus) > 0 ||
        parseInt(vm.oddActive) === 0 ||
        parseInt(vm.producerStatus) === 0;
      vm.getStatusClass();
      vm.directionClass = vm.getOddDirectionClass();
    },
    // getOddDirectionClass: function () {
    //   if (
    //     parseInt(this.producerStatus) === 0 ||
    //     parseInt(this.oddActive) === 0 ||
    //     parseInt(this.oddStatus) !== 0
    //   ) {
    //     return "";
    //   }
    //   if (parseFloat(this.odd) >= parseFloat(this.previousOdds)) {
    //     return "arrow green up";
    //   }
    //   return "arrow red down";
    // },

    getOddDirectionClass: function () {
      if (
        parseInt(this.producerStatus) === 0 ||
        parseInt(this.oddActive) === 0 ||
        parseInt(this.oddStatus) !== 0
      ) {
        return "";
      }
      let oddDirectionClass = "";
      if (!this.previousOdds) {
        return oddDirectionClass;
      }
      if (parseFloat(this.odd) >= parseFloat(this.previousOdds)) {
        oddDirectionClass = "arrow green up";
      } else {
        oddDirectionClass = "arrow red down";
      }
      this.oddDirectionClass = oddDirectionClass;
      if (this.oddTimeout) {
        clearTimeout(this.oddTimeout);
      }
      this.oddTimeout = setTimeout(() => {
        this.oddDirectionClass = "";
      }, 5000);

      return this.oddDirectionClass;
    },
    getStatusClass: function () {
      this.statusClass = this.locked ? "lock" : "";
    },
  },
  mounted: function () {
    this.odd = this.odds;
    this.oddStatus = this.status;
    this.oddActive = this.active;
    this.previousOdds = this.previous_odds;
    this.producerStatus = this.producer_status;

    if (parseInt(this.producer_id) === 1) {
      this.locked =
        parseInt(this.oddStatus) > 0 ||
        parseInt(this.oddActive) === 0 ||
        parseInt(this.producerStatus) === 0;
    } else {
      this.locked =
        parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0;
    }

    this.getStatusClass();

    this.id = this.getOddID(
      this.match_id,
      this.market_id,
      this.outcome_id,
      this.specifier,
      this.boosted ? "boosted" : "odd"
    );

    this.directionClass = this.getOddDirectionClass();
    var vm = this;

    // subscribe to UI event to update odds incase of odds change, this is done through event bus
    // we only expect to receive odds updates on this topic
    this.EventBus.$on("odds:" + vm.id, function (payload) {
      vm.oddStatus = payload.status;
      vm.oddActive = payload.active;

      if (parseFloat(payload.odds) !== parseFloat(vm.odd)) {
        vm.odd = payload.odds;
      }

      vm.locked =
        parseInt(vm.oddStatus) > 0 ||
        parseInt(vm.oddActive) === 0 ||
        parseInt(vm.producerStatus) === 0;

      vm.getStatusClass();

      vm.directionClass = vm.getOddDirectionClass();
    });

    // subscribe to UI event to update producer status incase of odds change, this is done through event bus
    // we only expect to receive producer status on this topic
    this.EventBus.$on("producer:status", function (payload) {
      var producer_id = payload.producer_id;
      var producer_status = payload.producer_status;

      if (parseInt(producer_id) === 1) {
        vm.producerStatus = producer_status;
        vm.locked =
          parseInt(vm.oddStatus) > 0 ||
          parseInt(vm.oddActive) === 0 ||
          parseInt(producer_status) === 0;
        vm.getStatusClass();
      }
    });

    // subscribe to UI event to update market status incase of odds change, this is done through event bus
    // we only expect to receive market status
    var topic = `status:match-${this.match_id}:market-${this.market_id}:specifier-${this.specifier}`;
    this.EventBus.$on(topic, function (payload) {
      vm.oddStatus = payload.status; // deactivate
      vm.locked = parseInt(vm.oddStatus) > 0;
      vm.getStatusClass();
    });
  },
  data: function () {
    return {
      iconSize: 13,
      id: "",
      odd: "",
      directionClass: "",
      oddStatus: "",
      oddActive: "",
      previousOdds: "",
      producerStatus: "",
      // locked: false,
      statusClass: "",
    };
  },
  computed: {
    picked: function () {
      var betsObject = this.$store.state.betslip;
      if (
        betsObject.picks !== undefined &&
        betsObject.picks[this.match_id] !== undefined &&
        betsObject.picks[this.match_id].id == this.id
      ) {
        // if (betsObject.picks[this.match_id].market_name === "total") {
        if (this.alias === betsObject.picks[this.match_id].alias) {
          return "picked";
        } else {
          return "";
        }
        // }
        // return "picked";
      } else {
        // console.log("not picked: " + this.id);
        return "";
      }
    },
    _betslip: function () {
      return this.$store.state.betslip;
    },
    isHot: function () {
      return parseFloat(this.probability) > 0.65;
    },
  },
  components: {
    LockIcon,
  },
};
</script>
